import React, {
	createRef,
	useEffect,
	useState,
	createContext,
	useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import ToolBar from "../ToolBar";
import Grid from "@material-ui/core/Grid";
import { getDimensions, normalizeAppointments } from "./utils";
import {
	filterAppointments,
	getAppointments,
	getFilterOptions,
} from "../../apis";
import WeekGridCell from "./WeekGridCell";
import MonthGridCell from "./MonthGridCell";
import AppointmentForm from "components/AppointmentForm";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./calendar.css";
import moment from "moment";
import config from "./config";
import { Link, Typography } from "@material-ui/core";
import { AuthorizationContext } from "components/App";
import ErrorNotification from "components/common/ErrorNotification";
import Worker from "./timeout.worker";
import useStyles from "./style";
import ModalAppointment from "./ModalAppointment";

/*
//  This web worker runs a setTimeout that this component uses to update appointments 
*/
let worker = new Worker();

/*
// This context stores results of the getAppointments API. The results for normalized
//  into: appointments (array of events/SubmissionIDs), and events (object where key
//  is SubmissionID and value is the appointment details) 
*/
export const AppointmentsContext = createContext();
function Calendar() {
	const calendarRef = createRef();
	const calendarContainerRef = createRef();
	const classes = useStyles();

	const [calendarApi, setCalendarApi] = useState();
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
	const [appointments, setAppointments] = useState({});
	const [activeAppointment, setActiveAppointment] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [formConfig, setFormConfig] = useState(null);
	const [activeDateRange, setActiveDateRange] = useState();
	const [view, setView] = useState();
	const [apiError, setApiError] = useState();
	const [currentDate, setCurrentDate] = useState(moment().format());
	const [events, setEvents] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const { authToken, filterOptions } = useContext(AuthorizationContext);

	const navigate = useNavigate();

	const [modalOpen, setModalOpen] = useState(false);
	const [activeAppointmentModal, setActiveAppointmentModal] = useState({
		appointmentID: "",
		name: "",
		time: "",
		date: "",
		phone: "",
		clinic: "",
		provider: "",
		appType: "",
		EMR: "",
		url: "",
	});

	const getOffset = () => {
		const date = new Date();
		return date.getTimezoneOffset();
	};

	useEffect(() => {
		let isMounted = true;
		const dimensions = getDimensions(calendarContainerRef.current);
		if (isMounted) {
			setDimensions(dimensions);
			let stringView = dimensions.width <= 768 ? "timeGridDay" : "dayGridMonth";
			calendarRef.current.getApi().changeView(stringView);

			setView(stringView);
		}
		return () => {
			isMounted = false;
			worker.terminate();
		};
	}, []);

	useEffect(() => {
		if (authToken) {
			setCalendarApi(calendarRef.current.getApi());
			const dateRange = formatDateRange(calculateViewBasedDateRange(moment()));
			setActiveDateRange(dateRange);
			worker.onmessage = ({ data: { dateRange, filters, options } }) => {
				updateAppointments({ dateRange, filters, options });
			};
			updateAppointments({ dateRange });
		}
	}, [authToken]);

	useEffect(() => {
		if (Object.keys(activeFilters).length > 0) {
			updateAppointments();
		}
	}, [activeFilters]);

	const renderAppointmentForm = (props) =>
		props && (
			<AppointmentForm
				closeModal={() => {
					console.log("added time out");
					setIsLoading(true);
					setTimeout(() => {
						updateAppointments();
					}, 3000);

					setFormConfig(null);
					navigate("/");
				}}
				{...props}
			/>
		);

	const calculateViewBasedDateRange = (dateObject, currentView = view) => {
		switch (currentView) {
			case "dayGridMonth":
				return {
					startDate: moment(dateObject).startOf("month"),
					// .set({ hour: 23, minute: 59, second: 59 }),
					endDate: moment(dateObject).endOf("month"),
					// .set({ hour: 23, minute: 59, second: 59 }),
				};
			case "listWeek":
				return {
					startDate: dateObject,
					endDate: moment(dateObject).add("604799", "seconds"),
				};
			case "dayGridDay":
				return {
					startDate: moment(dateObject).subtract(1, "seconds").startOf("day"),
					endDate: moment(dateObject).endOf("day"),
				};
			default:
				return {
					startDate: moment(dateObject).startOf("month"),
					endDate: moment(dateObject).endOf("month"),
				};
		}
	};

	const formatDateRange = ({ startDate, endDate }) => {
		return {
			startDate: moment(startDate)
				.subtract(1, "seconds")
				.format("MM/DD/YYYY HH:mm:ss"),
			endDate: moment(endDate).format("MM/DD/YYYY HH:mm:ss"),
		};
	};

	const isWithinCurrentDateRange = ({ startDate, endDate }) => {
		if (activeDateRange) {
			if (
				moment(startDate).isSameOrAfter(moment(activeDateRange.startDate)) &&
				moment(endDate).isSameOrBefore(moment(activeDateRange.endDate))
			) {
				return true;
			}
		}
		return false;
	};

	/*
  //  This function SHOULD BE pure in order to work correctl. All data to this function
  //  HAS TO be passed a param. This is because this function is called inside 
  //  a web worker. DO NOT depend on any external closures.
  */
	const updateAppointments = async (params = {}) => {
		const {
			dateRange = activeDateRange,
			filters = activeFilters,
			options = filterOptions,
		} = params;
		const numberOfDays = Math.ceil(
			moment(dateRange.endDate).diff(moment(dateRange.startDate), "days", true)
		);
		setIsLoading(true);
		let response;
		try {
			if (Object.keys(filters).length > 0) {
				response = await filterAppointments({
					...dateRange,
					...filters,
					authToken,
				});
			} else response = await getAppointments(dateRange, authToken);

			//  Below, we normalize the appointments array into two state variables:
			//  events- an array of arrays for each day within the range. The inner arrays
			//  contain SubmissionIDs ordered by the appointments in that day.
			//  appointments- an object whose keys are SubmissionIDs and value
			//  is an appointment object.
			const nonBlockedAppts = response.message.filter((appt) => {
				if (
					appt.LastName === "BLOCK" &&
					appt.FirstName === "PEG" &&
					appt.Email === "deliverypeg@nubiral.com"
				) {
					return false;
				}
				return true;
			});

			const {
				entities: { appointments = [] },
				result,
			} = normalizeAppointments(nonBlockedAppts);
			const events = new Array(numberOfDays).fill(0).map(() => []);
			result.forEach((item) => {
				const index = Math.ceil(
					moment
						.utc(appointments[item].AppointmentDate)
						.diff(moment(dateRange.startDate), "days", true)
				);

				events[index].push(item);
			});
			setEvents(events);
			setAppointments(appointments);

			//  Below, we call the web worker to update appointments every 10 minutes

			console.log(
				"filters being called from the worker",
				Object.keys(filters).reduce((acc, key) => {
					return {
						...acc,
						[key]: options[key].indexOf(filters[key]),
					};
				}, {})
			);
			worker.postMessage({
				delay: config.autoUpdateDelayInMilliseconds,
				dateRange,
				filters: Object.keys(filters).reduce((acc, key) => {
					return {
						...acc,
						[key]: options[key].indexOf(filters[key]),
					};
				}, {}),
				options,
			});
			setIsLoading(false);
		} catch ({ message, cancelToken }) {
			if (typeof cancelToken === typeof undefined) {
				setIsLoading(false);
			}
			setApiError(message);
		}
	};

	const scrollToAppointment = (el) => {
		/*
    //  The 35px adjusts for the sticky <th> element that displays the day and date
    */
		document.querySelector(".fc-scroller").scrollTop = el.offsetTop - 35;
	};

	const scrollToTop = (el) => {
		document.querySelector(".fc-scroller").scrollTop = 0;
	};

	const onClickAppointment = (id) => {
		const currentDate = appointments[id].AppointmentDate;
		setView("listWeek");
		calendarApi.changeView("listWeek", currentDate);
		setActiveAppointment(id);
		calendarApi.gotoDate(currentDate);
		const startOfWeek = moment(currentDate).startOf("week");
		setCurrentDate(startOfWeek.format());
		/*
    //  The range is calculated from the start of the week (Sunday) until
    //  11:59:59 pm of the next Saturday.
    */
		const dateRange = formatDateRange({
			startDate: startOfWeek,
			endDate: moment(startOfWeek).add("604799", "seconds"),
		});
		if (!isWithinCurrentDateRange(dateRange)) {
			setActiveDateRange(dateRange);
			updateAppointments({ dateRange });
		}
	};

	function renderEventContent(eventInfo) {
		const appointment = appointments[eventInfo.event._def.publicId];
		if (eventInfo.view.type === "listWeek") {
			return (
				<WeekGridCell
					appointment={appointment}
					onClickEdit={onClickEdit}
					updateAppointments={updateAppointments}
					{...eventInfo}
				/>
			);
		}
	}

	function renderCellContent(params) {
		const {
			view: { type },
			dayNumberText,
		} = params;
		let appointmentsToday = events[parseInt(dayNumberText) - 1]
			? events[parseInt(dayNumberText) - 1].map((item) => appointments[item])
			: [];
		const sorter = (a, b) => {
			return moment(a.AppointmentStartTime) - moment(b.AppointmentStartTime);
		};
		appointmentsToday.sort(sorter);

		// for (let i = 0; i < appointmentsToday.length; i++) {
		//   if(appointmentsToday[i] && moment().format("D") === dayNumberText) {
		//     if(moment(appointmentsToday[i].AppointmentStartTime) - moment().subtract(15, "minutes") < 0){
		//       // if(moment(appointmentsToday[i].AppointmentStartTime).isBefore()){
		//       appointmentsToday.shift()
		//       i--
		//     }
		//   }
		// }
		switch (type) {
			case "dayGridMonth":
				return (
					<MonthGridCell
						appointments={appointmentsToday}
						dayNumberText={dayNumberText}
						onClickAppointment={onClickAppointment}
						cellHeight={Math.floor(dimensions.height / 5)}
					/>
				);
			case "timeGridDay":
				return (
					<MonthGridCell
						appointments={appointmentsToday}
						dayNumberText={dayNumberText}
						onClickAppointment={onClickAppointment}
					/>
				);
			default:
				return null;
		}
	}

	const onClickSchedule = () => {
		navigate("/provider");
		setFormConfig({
			iFrameId: `JotFormIFrame-${config.newFormId}`,
			src: `${config.src}/${config.newFormId}`,
		});
	};

	const onClickEdit = ({ id, wasMadeByProvider }) => {
		if (wasMadeByProvider) {
			navigate("/provider");
		} else {
			navigate("/self");
		}

		setFormConfig({
			iFrameId: `JotFormIFrame-${id}`,
			src: `${config.src}/edit/${id}`,
		});
	};

	const createDateObjectFromTimeString = (timeString) =>
		moment(timeString, "h:m a").toDate();

	const changeDateOnDateObject = (targetObject, sourceObject) => {
		const date = moment(sourceObject).date();
		const month = moment(sourceObject).month();
		const year = moment(sourceObject).year();
		return moment(targetObject).set({ date, month, year }).toDate();
	};

	const handleOpenModal = () => setModalOpen(true);
	const handleCloseModal = () => setModalOpen(false);
	return (
		<AppointmentsContext.Provider value={{ appointments, events }}>
			<Grid
				className={classes.root}
				item
				xs={12}
				container
				direction="column"
				wrap="nowrap">
				{isLoading && <LinearProgress className={classes.progressBar} />}
				<ToolBar
					calendarApi={calendarApi}
					onClickEdit={onClickEdit}
					onClickSchedule={onClickSchedule}
					currentView={view}
					currentDate={currentDate}
					setCurrentDate={setCurrentDate}
					activeFilters={activeFilters}
					setActiveFilters={setActiveFilters}
					filterOptions={filterOptions}
					updateAppointments={updateAppointments}
					setCurrentView={(e) => {
						setView(e);
						calendarApi.changeView(e, activeDateRange.startDate);
					}}
					getAppointments={({ baseDate, currentView = view }) => {
						setActiveAppointment(undefined);
						const dateRange = formatDateRange(
							calculateViewBasedDateRange(baseDate, currentView)
						);
						/*
                  //  We need to scroll to top when next/previous is clicked in the
                  //  listWeek view in order to reset scrollToAppointment
                  */
						if (view === "listWeek") scrollToTop();
						if (!isWithinCurrentDateRange(dateRange)) {
							//  Below, we clear the events while we wait for the updated appointments. This
							//  will clear all the appointments in the FullCalendar view.
							setEvents([]);
							setAppointments({});
							setActiveDateRange(dateRange);
							updateAppointments({ dateRange });
						}
					}}
				/>
				<Grid
					ref={calendarContainerRef}
					className={classes.calendarRoot}
					item
					xs={12}>
					{view === "dayGridMonth" || view === "listWeek" ? (
						<FullCalendar
							ref={calendarRef}
							plugins={[dayGridPlugin, listPlugin]}
							initialView={view}
							headerToolbar={false}
							height="100%"
							contentHeight={50}
							showNonCurrentDates={false}
							eventContent={renderEventContent}
							dayCellContent={renderCellContent}
							eventDisplay={view === "dayGridMonth" ? "none" : "auto"}
							events={Object.values(appointments).map(
								({
									AppointmentID,
									FirstName,
									LastName,
									AppointmentStartTime,
									AppointmentEndTime,
									ClinicName,
									PatientEMRNumber,
									Phone,
									SelectProvider,
									AppointmentType,
									TeleHealthURL,
									SubmissionID,
									InPersonOrTelehealth,
								}) => ({
									id: SubmissionID,
									title: `${FirstName}, ${LastName}`,
									start: moment(AppointmentStartTime).format(),
									end: changeDateOnDateObject(
										createDateObjectFromTimeString(AppointmentEndTime),
										AppointmentStartTime
									),
									extendedProps: {
										appointmentID: AppointmentID,
										submittionID: SubmissionID,
										phone: Phone,
										clinicName: ClinicName,
										provider: SelectProvider,
										appType: AppointmentType,
										EMR: PatientEMRNumber,
										url: TeleHealthURL,
										inPersonOrTelehealth: InPersonOrTelehealth,
									},
								})
							)}
							noEventsContent={() =>
								!isLoading && (
									<Typography>No appointments scheduled.</Typography>
								)
							}
							eventDidMount={(eventInfo) => {
								if (activeAppointment === eventInfo.event._def.publicId) {
									scrollToAppointment(eventInfo.el);
								}
							}}
						/>
					) : (
						<FullCalendar
							ref={calendarRef}
							plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
							initialView={view}
							headerToolbar={false}
							height="100%"
							contentHeight={50}
							eventMinHeight={50}
							eventMaxStack={2}
							expandRows={true}
							showNonCurrentDates={false}
							events={Object.values(appointments).map(
								({
									AppointmentID,
									FirstName,
									LastName,
									AppointmentStartTime,
									AppointmentEndTime,
									ClinicName,
									PatientEMRNumber,
									Phone,
									SelectProvider,
									AppointmentType,
									TeleHealthURL,
									SubmissionID,
									InPersonOrTelehealth,
									AppIdHash,
									Cancel,
									PatientID,
								}) => ({
									id: SubmissionID,
									title: `${FirstName}, ${LastName}`,
									start: moment(AppointmentStartTime).format(),
									end: changeDateOnDateObject(
										createDateObjectFromTimeString(AppointmentEndTime),
										AppointmentStartTime
									),
									extendedProps: {
										appointmentID: AppointmentID,
										submittionID: SubmissionID,
										phone: Phone,
										clinicName: ClinicName,
										provider: SelectProvider,
										appType: AppointmentType,
										EMR: PatientEMRNumber,
										url: TeleHealthURL,
										inPersonOrTelehealth: InPersonOrTelehealth,
										idHash: AppIdHash,
										appointmentStartTime: AppointmentStartTime,
										appointmentEndTime: AppointmentEndTime,
										patientID: PatientID,
									},
									color: Cancel ? "#979797" : "#00BEB5",
								})
							)}
							eventColor="#00BEB5"
							eventClick={(args) => {
								const time = `${moment(args.event.start).format(
									"hh:mm a"
								)} - ${moment(args.event.end).format("hh:mm a")}`;
								const date = moment(args.event.start).format("MMMM Do, YYYY");
								setActiveAppointmentModal({
									appointmentID: args.event.extendedProps.appointmentID,
									submittionID: args.event.extendedProps.submittionID,
									name: args.event.title,
									time: time,
									date: date,
									phone: args.event.extendedProps.phone,
									clinic: args.event.extendedProps.clinicName,
									provider: args.event.extendedProps.provider,
									appType: args.event.extendedProps.appType,
									EMR: args.event.extendedProps.EMR,
									url: args.event.extendedProps.url,
									inPersonOrTelehealth:
										args.event.extendedProps.inPersonOrTelehealth,
									idHash: args.event.extendedProps.idHash,
									appointmentStartTime:
										args.event.extendedProps.appointmentStartTime,
									appointmentEndTime:
										args.event.extendedProps.appointmentEndTime,
									patientID: args.event.extendedProps.patientID,
								});
								handleOpenModal();
								//onClickEdit(SubmissionID)
							}}
						/>
					)}
				</Grid>
			</Grid>
			{renderAppointmentForm(formConfig)}
			<ErrorNotification
				open={apiError}
				title="Failed to load appointments:"
				message={apiError}
				onClose={() => setApiError(undefined)}
				severity="error"
			/>
			<ModalAppointment
				appointment={activeAppointmentModal}
				handleClose={handleCloseModal}
				handleEdit={onClickEdit}
				open={modalOpen}
			/>
		</AppointmentsContext.Provider>
	);
}

Calendar.propTypes = {};

export default Calendar;
